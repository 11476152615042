import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import StoreItem from "../template/StoreItem"
import Discount from "../template/Discount"

import { iconBanner1, iconBanner2, iconBanner3 } from "../utils/imageUpload"
import { useStore } from "../context/TestContext"
import { useShop } from "../hooks"
import BodyWrapper from "../components/BodyWrapper"

export const query = graphql`
  query ALL_PRODUCTS {
    shopifyCollection(handle: { eq: "shop" }) {
      products {
        id
        handle
        description
        title

        shopifyId
        productType
        variants {
          shopifyId
          price
          availableForSale
        }
        images {
          id
          localFile {
            childImageSharp {
              fluid {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags
        createdAt(locale: "")
      }
    }
  }
`

const Shop = ({ data }) => {
  // console.log("data:", data)
  const { handleChange, list, search, types, sortType, select } = useShop(data)

  return (
    <Layout>
      <SEO title="Shop" />

      <Discount />
      <BodyWrapper>
        <Wrapper>
          <div className="filter-div">
            <select
              className="select-type"
              onChange={sortType}
              placeholder="search by type"
              name="select"
              value={select}
              id=""
            >
              <option value="1" disabled>
                Filter by Type
              </option>

              <option value="all">all products</option>

              {types.map(type => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                )
              })}
            </select>
            <input
              onChange={handleChange}
              type="text"
              placeholder="Search Products "
              type="text"
              value={search}
            />
          </div>
          <Grid>
            {list.map((product, i) => (
              <StoreItem key={i} product={product} i={i} />
            ))}
          </Grid>
        </Wrapper>
      </BodyWrapper>
      <Banner>
        <div>
          <img width="300px" src={iconBanner1} alt="" />
          <h2>ORDER GOODIES WHENEVER</h2>
          <p>
            What could be better than Belgian Boys delivered to your doorstep?
            Order goodies from whenever and we’ll get them to you ASAP!
          </p>
        </div>
        <div>
          <img width="300px" src={iconBanner2} alt="" />
          <h2>WHEN INDULGING, INDULGE WELL</h2>
          <p>
            All Belgian Boys products are made in Europe from high quality,
            non-GMO ingredients. Treat yourself with the best!
          </p>
        </div>
        <div>
          <img width="300px" src={iconBanner3} alt="" />
          <h2>THE MORE THE MERRIER</h2>
          <p>
            Our bundles are a great way to save on your faves and keep your
            pantry stocked with goodies for any snacking occasion!
          </p>
        </div>
      </Banner>
    </Layout>
  )
}

const Wrapper = styled.div`
  .filter-div {
    height: 100%;

    height: 15vh;
    display: flex;
    align-items: flex-end;

    input {
      border-left: 1px solid var(--darkBlue);
      border-right: none;
      border-top: none;
      background: transparent;
    }
    select {
      text-align: center;
      border-right: none;
      border-top: none;
      border-left: none;
      /* text-transform: uppercase; */
      text-transform: capitalize;

      -webkit-border-radius: 0px;
      background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='15' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill=' Violent Violet'/></g></svg>")
        no-repeat;
      background-position: right 10px top 50%;
    }

    input,
    select {
      -webkit-appearance: none;
      width: 200px;
      padding: 10px 10px;
      color: var(--darkBlue);
      border-bottom: 2px solid var(--darkBlue);
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--darkBlue);
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--darkBlue);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--darkBlue);
      }

      :focus {
        outline: none;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 95%;
  }
  @media (min-width: 1500px) {
    width: 100%;
  }
  width: 75%;
  margin: 0 auto;
`

const Grid = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  justify-items: center;
  align-items: center;
  display: grid;

  grid-gap: 4rem;
  justify-content: center;

  margin: 50px auto;

  @media (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 678px) {
    grid-gap: 1rem;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px));

    .button-container {
      flex-direction: column;

      .btn-div {
        border: none;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;

        button {
          border-radius: 50%;
          border: 1px solid var(--pink);
          padding: 10px 15px;
        }
      }

      .add-to-cart-btn {
        padding: 10px 20px;

        background: var(--lightBlue);
      }
    }

    .title {
      font-size: 0.9em;
      width: 100%;
      margin: 0.67em 0;
    }
  }
`

const Banner = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: var(--beige);
  width: 100vw;
  text-align: center;

  div {
    padding: 50px 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    color: var(--pink);
    font-weight: bolder;
    margin: 0.67em auto;
  }

  p {
    color: var(--darkBlue);
    width: 80%;
  }

  @media (max-width: 1024px) {
    padding: 20px 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      width: 90%;
    }
  }
`
export default Shop
