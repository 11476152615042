import React from "react"
import styled from "styled-components"

const BodyWrapper = ({ children, bg, always, gallery }) => {
  return (
    <BodyWrapperStyles always={always} bg={bg} gallery={gallery}>
      <div className="sonOfWrapper">{children}</div>
    </BodyWrapperStyles>
  )
}

const BodyWrapperStyles = styled.div`
  width: 100%;

  background: ${props => props.bg || "none"};
  background-color: ${props => props.bg || "none"};

  .sonOfWrapper {
    margin: ${props => (props.always ? "0 auto" : "")};
    max-width: ${props => (props.always ? "var(--hugeInNav)" : "")};

    display: ${props => (props.gallery ? "flex" : "")};
    justify-content: ${props => (props.gallery ? "space-between" : "")};
  }

  @media (min-width: 1500px) {
    .sonOfWrapper {
      margin: 0 auto;
      max-width: var(--hugeInNav);
    }
  }
`

export default BodyWrapper
