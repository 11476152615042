import React, { useState } from "react"
import styled from "styled-components"
// import { useStore } from "../Context/StoreContext"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { useStore } from "../context/TestContext"
import { useCounter } from "../hooks/"
import ReactPixel from "react-facebook-pixel"
import { Cookies } from "../utils/imageUpload"

const StoreItem = ({ product, i }) => {
  const [productImgIndex, setproductImgIndex] = useState(0)
  const { counter, add, remove } = useCounter(1)

  const changeImg = bool => {
    if (bool) {
      setproductImgIndex(1)
    } else {
      setproductImgIndex(0)
    }
  }

  const { addToCart, state } = useStore()

  function addToCartBonanza() {
    const {
      title,
      variants,
      variants: [{ price, shopifyId }],
    } = product

    const image = product?.images?.[0]?.localFile?.childImageSharp?.fluid

    const args = {
      title,
      price,
      quantity: counter,
      image,
      variantId: shopifyId,
      variants,
    }

    if (state.isLoading) {
      return
    } else {
      addToCart(args)
      ReactPixel.track("AddToCart", {
        content_name: product.title,
        value: product.variants[0].price,
        currency: "USD",
        num_items: counter,
      })
    }
  }

  const multipleOptions = product.variants.length > 1

  return (
    <GridItem
      key={product.id}
      onMouseOver={() => changeImg(true)}
      onMouseOut={() => changeImg(false)}
    >
      <Link className="item-link" to={`/product/${product.handle}`}>
        <div className="item-container">
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {product.images.length <= 1 || product.tags.includes("bundles") ? (
              <Image
                imgStyle={{
                  marginBottom: "0",
                  objectFit: "contain",
                }}
                fluid={
                  product?.images?.[0]?.localFile?.childImageSharp?.fluid ||
                  Cookies
                }
                className="productImg"
              />
            ) : (
              <Image
                imgStyle={{
                  marginBottom: "0",
                  objectFit: "contain",
                }}
                fluid={
                  product.images[productImgIndex].localFile.childImageSharp
                    .fluid
                }
                className="productImg"
              />
            )}
          </div>
        </div>
      </Link>

      <p className="title">{product.title}</p>
      <p className="productPrice">${product.variants[0].price}</p>
      {/*  */}

      <div className="productInfo-container">
        {product.tags.includes("outOfStock") ? (
          <button
            disabled
            className="add-to-cart-btn outOfStock"
            style={{ width: "100%" }}
          >
            Out of stock
          </button>
        ) : product.tags.includes("pick") ? (
          <Link className="item-link" to={`/product/${product.handle}`}>
            <button className="add-to-cart-btn" style={{ width: "100%" }}>
              Choose your items
            </button>
          </Link>
        ) : !multipleOptions ? (
          <div className="button-container">
            <div className="btn-div">
              <button onClick={remove} className="btn-count">
                -
              </button>
              <p>{counter}</p>
              <button onClick={add} className="btn-count">
                +
              </button>
            </div>

            <button
              className="add-to-cart-btn"
              disabled={state.isLoading}
              onClick={addToCartBonanza}
            >
              Quick Add
            </button>
          </div>
        ) : (
          <Link className="item-link" to={`/product/${product.handle}`}>
            <button className="add-to-cart-btn" style={{ width: "100%" }}>
              {product.tags.includes("qtyOption")
                ? "Choose Your Pack"
                : "Choose your size"}
            </button>
          </Link>
        )}
      </div>
    </GridItem>
  )
}

export default StoreItem

const GridItem = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .productInfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .button-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }
  }
  .title {
    color: var(--darkBlue);
    font-weight: 900;
    font-size: 1.1em;
    width: 90%;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .productPrice {
    font-size: 1.1em;
    color: var(--pink);
    font-weight: 900;
    margin-top: 0;
    margin: 0 0 0.67em 0;
  }

  .btn-div {
    display: flex;

    backround: white;
    border-radius: 20px;
    color: var(--pink);
    border: 1px solid var(--pink);
    align-items: center;
    font-weight: bolder;

    p {
      margin: 0;
    }

    button {
      padding: 10px 20px;
      background: transparent;
      color: var(--pink);
      border: none;
      font-weight: bolder;
    }
  }

  .add-to-cart-btn {
    background-color: var(--lightBlue);
    color: white;
    font-size: 0.8em;
    font-weight: 900;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    padding: 10px 25px;

    @media (min-width: 1500px) {
      font-size: 0.6em;
    }
  }

  .item-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .item-link {
    width: 100%;
  }
`
