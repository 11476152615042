import React, { useState } from "react"

import styled from "styled-components"

const Discount = () => {
  return (
    <DiscountBanner>
      <h2>
        Free shipping on orders over <span>50$</span>
      </h2>
      {/*   <h2>
        20% OFF SITEWIDE WITH CODE <span>BYESUMMER20 </span>
      </h2> */}
      {/* <h2>
        Buy One Belgian Waffle 6 Pack, Get One 6 Pack Free with code
        <span>{" "}WAFFLEDAYBOGO</span>
      </h2> */}
      {/* <h2>20% OFF WITH CODE SPARKLERS20</h2> */}
    </DiscountBanner>
  )
}

export default Discount

const DiscountBanner = styled.div`
  background-color: var(--lightBlue);
  color: white;

  width: 100vw;
  text-align: center;
  padding: 15px 0;
  text-transform: uppercase;

  h2 {
    font-weight: 500;
  }
  span {
    font-weight: 900;
  }
`
